@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    background-color: rgb(243 244 246);
}

body {
    min-height: 100vh;
    min-height: -webkit-fill-available;
    background-color: rgb(243 244 246);
    font-family: -apple-system, BlinkMacSystemFont, 'SF Pro Text', 'SF Pro Display', system-ui, Helvetica, Arial, sans-serif;
}

html {
    height: -webkit-fill-available;
}

#root {
    min-height: 100vh;
    padding-top: env(safe-area-inset-top);
    background-color: rgb(243 244 246);
}